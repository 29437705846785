<template>
  <v-card>
    <v-form
      v-show="!showProgressBar"
      v-model="formIsValidated"
      ref="keystoneV2Form"
      :lazy-validation="false"
      class="keystoneV2-form-content"
    >
      <v-toolbar tabs>
        <v-toolbar-title>
          <v-row>
            <v-col>
              <slot :entity="entity" name="title">
                <span>
                  {{ titleForm }}
                </span>
              </slot>
            </v-col>
          </v-row>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn class="ma-2" color="primary" :disabled="!formValidation() || disabledSave" @click="__saveItemProcess(entity)">
          SAVE
        </v-btn>

        <v-btn icon @click="__closeDialogProcess" class="ma-0">
          <v-icon>close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs
            :value="activeTab"
            @change="updateActiveTab($event)"
          >
            <v-tab v-for="(item, key) in tabItems" :key="key" :ref="item.title" :disabled="isItemDisabled(item)">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-divider></v-divider>

      <v-tabs-items
          :value="activeTab"
          @change="updateActiveTab($event)"
          class="main-tabs-item"
      >
        <v-tab-item
          eager
          v-for="(item, key) in tabItems"
          :key="key"
          class="tab-item-ksv2"
        >
          <v-card class="ma-6 pa-2" :class="{'border-top': !noBorderTop, 'elevation-0': noElevation}">
            <slot
              :entity="entity"
              :name="item.title"
            >
            </slot>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-divider></v-divider>
    </v-form>
    <v-card v-show="showProgressBar" class="process-loading">
      <div>
        {{progressBarMessage}}
      </div>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      >
      </v-progress-circular>
    </v-card>
    <v-dialog eager :value="dialogUpdated" width="450px">
      <v-card>
        <v-card-title class="headline">
          Entity updated
        </v-card-title>
        <v-card-text>
          <div v-if="entity.hasBeenUpdated()">Warning ! The item has been updated and you are going to close the form. Are you sure ?</div>
          <div v-if="confirmBeforeQuitMessage && confirmBeforeQuit" v-html="confirmBeforeQuitMessage"></div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="cancelDialogUpdated"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="onClickClose"
          >
            {{ dialogUpdatedIsSave ? 'Save' : 'Close' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import * as keystoneV2Utils from '../../../utils/keystoneV2Utils'

export default {
  name: 'BaseEditCardEntity',
  data: function () {
    return {
      formIsValidated: false,
      dialogUpdated: false,
      isProcessing: false,
      dialogUpdatedIsSave: false
    }
  },
  props: {
    activeTab: {
      type: Number,
      default: 0
    },
    tabItems: {
      type: Array,
      default: function () {
        return []
      }
    },
    isEdit: {
      type: Boolean
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    confirmBeforeQuitMessage: {
      type: String,
      default: ''
    },
    entity: {
      type: BaseKeystoneEntity,
      default: function () {
        return {}
      }
    },
    entityType: {
      validator: keystoneV2Utils.validateEntityType
    },
    saveItem: {
      type: Function,
      default: function (entity) {
        if (!this.formValidation()) {
          console.warn('form not validated')
          return
        }
        this.$emit('close')
        if (this.isEdit) {
          this.$emit('saveitemedit', entity)
        } else {
          this.$emit('saveitemnew', entity)
        }
        this.$refs.keystoneV2Form.reset()
      }
    },
    closeDialog: {
      type: Function,
      default: function () {
        this.$emit('close')
      }
    },
    noBorderTop: {
      type: Boolean,
      default: false
    },
    noElevation: {
      type: Boolean,
      default: false
    },
    disabledSave: {
      type: Boolean,
      default: false
    },
    confirmBeforeQuit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formValidation () {
      if (!this.formIsValidated) {
        if (this.$refs && this.$refs.keystoneV2Form) {
          this.$refs.keystoneV2Form.validate()
        }
        return false
      }

      return true
    },
    cancelDialogUpdated () {
      this.dialogUpdated = false
    },
    closeDialogUpdated () {
      this.dialogUpdated = false
      this.__callCloseDialogProp()
    },
    async __saveItemProcess (entity) {
      // if an autoplug is open without being validated, we want the update dialog to ask for confirmation before save
      if (this.confirmBeforeQuit) {
        this.openDialogUpdated(true)
      } else {
        await this.callSaveItemFunc(entity)
      }
    },
    async callSaveItemFunc (entity) {
      this.dialogUpdated = false
      this.dialogUpdatedIsSave = false
      const saveItemFunc = this.saveItem.bind(this)
      this.isProcessing = true
      await saveItemFunc(entity)
      this.isProcessing = false
    },
    openDialogUpdated (dialogUpdatedIsSave = false) {
      this.dialogUpdatedIsSave = dialogUpdatedIsSave
      this.dialogUpdated = true
    },
    __closeDialogProcess () {
      if (this.entity.hasBeenUpdated() || this.confirmBeforeQuit) {
        this.openDialogUpdated()
      } else {
        this.__callCloseDialogProp()
      }
    },
    __callCloseDialogProp () {
      this.dialogUpdatedIsSave = false
      const closeDialogFunc = this.closeDialog.bind(this)
      closeDialogFunc()
    },
    isItemDisabled (tabItem) {
      if (tabItem.disabled === undefined) {
        return false
      }

      if (typeof tabItem.disabled === 'function') {
        return tabItem.disabled()
      }

      return tabItem.disabled
    },
    updateActiveTab ($event) {
      this.$emit('update:activeTab', $event)
    },
    onClickClose () {
      if (this.dialogUpdatedIsSave) {
        this.callSaveItemFunc(this.entity)
      } else {
        this.closeDialogUpdated()
      }
    }
  },
  computed: {
    showProgressBar () {
      return this.isProcessing || this.isLoading
    },
    titleForm () {
      return `${this.isEdit ? 'Edit' : 'New'} item | ${this.entityType} ${this.entityName}`
    },
    entityName () {
      if (['metaholding', 'holding', 'company'].indexOf(this.entityType) !== -1) {
        return this.entity && this.entity?.name != null ? this.entity.name : ''
      } else if (this.entityType === 'siege') {
        return this.entity && this.entity?.siege?.name != null ? this.entity.siege.name : ''
      } else if (this.entityType === 'member') {
        return this.entity && this.entity?.member?.name != null ? this.entity.member.name : ''
      } else {
        return ''
      }
    },
    progressBarMessage () {
      return this.isLoading ? 'Load item...' : 'Update process...'
    }
  },
  watch: {}
}
</script>

<style scoped>
  .border-top {
    border-top: 5px solid cornflowerblue;
    min-height: 50vh;
  }

  .tab-item-ksv2 {

  }

  .keystoneV2-form-content {
    height: 85vh;
    font-size: 14px;
  }

  .process-loading {
    text-align: center;
    padding: 2em;
  }

  .main-tabs-item {
    height: 66vh;
    overflow: auto;
  }

  .text-red {
    color: #ff5252;
  }
</style>
